import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanMatchFn, Params, Router, UrlSegment, UrlTree } from '@angular/router';
import { combineLatest, map, Observable, of } from 'rxjs';

import { LDFeatureKey } from '@@core/services/launch-darkly.service';

import { FeatureService } from '../services/features.service';

export const ldFeatureMatchGuard: CanMatchFn = (route: ActivatedRouteSnapshot, segments: UrlSegment[]) => {
	const featureService = inject(FeatureService);
	const router = inject(Router);

	const features = route.data?.features as LDFeatureKey[] || [];

	return checkRouteEnabled(features, featureService).pipe(
		map(routeEnabled => {
			if (!routeEnabled) {
				const params = route.params || {};
				const queryParams = route.queryParams || {};
				const redirectTo = route.data?.redirectTo as string || '/';

				if (route.data?.keepQueryParams) {
					return replaceUrlSegments(router, redirectTo, params, queryParams);
				}
				return router.createUrlTree([redirectTo]);
			}
			return true;
		})
	);
};

const checkRouteEnabled = (features: LDFeatureKey[], featureService: FeatureService): Observable<boolean> => isAtLeastOneFlagActive(features, featureService);

const isAtLeastOneFlagActive = (features: LDFeatureKey[], featureService: FeatureService): Observable<boolean> => {
	if (!features || features.length === 0) {
		return of(false);
	}

	const observables = features.map(feature => featureService.isFeatureOn(feature));

	return combineLatest(observables).pipe(
		map(results => results.some(result => result))
	);
};

const replaceUrlSegments = (router: Router, redirectTo: string, params: Params, queryParams: Params): UrlTree => {
	const segments = redirectTo.split('/').map(segment => {
		if (segment.startsWith(':')) {
			const paramKey = segment.substring(1);
			return params[paramKey] as string[];
		}
		return segment;
	});
	return router.createUrlTree(segments, { queryParams });
};
